import { useTranslation } from 'react-i18next';
import '../header/Header.css';

const SelectLanguage = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
        <select onChange={event => changeLanguage(event.target.value)} name="language" className="lang-switch">
            <option selected={i18n.language === 'ua'} value="ua">Українська</option>
            <option selected={i18n.language === 'en'} value="en">English</option>
        </select>
    );
}

export default SelectLanguage;