import { useTranslation } from 'react-i18next';
import './NotFound.css';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <>
        <div className='not-found-page'>
            <h1>
                {t('errors.page-not-found')}
            </h1>
        </div>
        </>
    )
}

export default NotFound;