import DonationButton from "./DonationButton";
import './Donation.css';
import { useState } from "react";
import { useTranslation } from "react-i18next";

const donations = [
    {
        imgUrls: [
            "./images/donate-4.jpg",
            "./images/donate-3.jpg",
            "./images/donate-1.jpg",
            "./images/donate-2.jpg"
        ],
        collected: "$28686",
        collectedValue: 256,
        progress: 83,
        title: "На бронежилети",
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
        goal: "$34562",
        goalValue: 34562,
        remaining: "$5875",
        remainingValue: 562,
        donateUrl: "/donate/bronjeljeti"
    },
    {
        imgUrls: [
            "./images/donate-3.jpg",
            "./images/donate-1.jpg",
            "./images/donate-2.jpg",
            "./images/donate-4.jpg"
        ],
        collected: "$18654",
        collectedValue: 154,
        progress: 52,
        title: "На медичне обладнання",
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
        goal: "$35000",
        goalValue: 35000,
        remaining: "$16346",
        remainingValue: 16346,
        donateUrl: "/donate/medical-equipment"
    },
    {
        imgUrls: [
            "./images/donate-2.jpg",
            "./images/donate-4.jpg",
            "./images/donate-3.jpg",
            "./images/donate-1.jpg"
        ],
        collected: "$22000",
        collectedValue: 220,
        progress: 44,
        title: "На гуманітарну допомогу",
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
        goal: "$50000",
        goalValue: 50000,
        remaining: "$28000",
        remainingValue: 28000,
        donateUrl: "/donate/humanitarian-aid"
    },
    {
        imgUrls: [
            "./images/donate-1.jpg",
            "./images/donate-3.jpg",
            "./images/donate-4.jpg",
            "./images/donate-2.jpg"
        ],
        collected: "$15000",
        collectedValue: 150,
        progress: 75,
        title: "На освітні програми",
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
        goal: "$20000",
        goalValue: 20000,
        remaining: "$5000",
        remainingValue: 5000,
        donateUrl: "/donate/education"
    }
];


const Donation = ({ title, showPageRedirectButton = true }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageList, setImageList] = useState([]);

    const { t } = useTranslation();

    const openModal = (images, index) => {
        setImageList(images);
        setCurrentImageIndex(index);
        setSelectedImage(images[index]);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setImageList([]);
        setCurrentImageIndex(0);
    };

    const showNextImage = (e) => {
        e.stopPropagation();
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        setSelectedImage(imageList[(currentImageIndex + 1) % imageList.length]);
    };

    const showPreviousImage = (e) => {
        e.stopPropagation();
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length);
        setSelectedImage(imageList[(currentImageIndex - 1 + imageList.length) % imageList.length]);
    };

    return (
        <section className="section donate" id="donate">
            <div className="container">
                <h2 className="h2 section-title"><strong>{title}</strong></h2>
                <br />
                <br />
                <br />
                <ul className="donate-list">
                    {donations.map((donation, index) => (
                        <li key={index}>
                            <div className="donate-card">
                                <figure className="card-banner">
                                    <img onClick={() => openModal(donation.imgUrls, 0)} src={donation.imgUrls[0]} width="520" height="325" loading="lazy" alt="Elephant"
                                        className="img-cover" />
                                </figure>
                                <div className="card-content">
                                    <div className="progress-wrapper">
                                        <p className="progress-text">
                                            <span>{t('donate.collected-money')}</span>
                                            <data value={donation.collectedValue}>{donation.collected}</data>
                                        </p>
                                        <data className="progress-value" value={donation.progress}>{donation.progress}%</data>
                                    </div>
                                    <div className="progress-box">
                                        <div className="progress"></div>
                                    </div>
                                    <h3 className="h3 card-title">{donation.title}</h3>
                                    <div className="card-wrapper">
                                        <p className="card-wrapper-text">
                                            <span>{t('donate.goal')}</span>
                                            <data className="green" value={donation.goalValue}>{donation.goal}</data>
                                        </p>
                                        <p className="card-wrapper-text">
                                            <span>{t('donate.left-to-collect')}</span>
                                            <data className="yellow" value={donation.remainingValue}>{donation.remaining}</data>
                                        </p>
                                    </div>
                                    <DonationButton linkForPayment={donation.donateUrl} modalTitile={donation.title} modalDescription={donation.description} />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                {showPageRedirectButton ? (
                    <a href="/donate" className="go-to-donate btn btn-secondary">
                        <span>{t('donate.showmore')}</span>
                    </a>
                ) : null}
                {selectedImage && (
                    <div className="modal-reports" onClick={closeModal}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img className="modal-content" src={selectedImage} alt="Selected" />
                        <button className="prev" onClick={showPreviousImage}>&#10094;</button>
                        <button className="next" onClick={showNextImage}>&#10095;</button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Donation;
