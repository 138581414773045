import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Main from './components/main/Main';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AboutPage from './components/about/AboutPage';
import ContactsPage from './components/contacts/ContactsPage';
import DonationPage from './components/donation/DonationPage';
import ReportsPage from './components/reports/ReportsPage';
import NotFound from './components/not-found/NotFound';
import { useTranslation } from 'react-i18next';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
  },
  {
    path: '/about',
    element: <AboutPage/>,
  },
  {
    path: '/contacts',
    element: <ContactsPage/>
  },
  {
    path: '/reports',
    element: <ReportsPage/>
  },
  {
    path: '/donate',
    element: <DonationPage/>
  },
  {
    path: '*',
    element: <NotFound />
  }
]);

const App = () => {
  return (
    <React.Fragment>
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </React.Fragment>
  );
};

export default App;