import { useTranslation } from 'react-i18next';
import './Contacts.css';

const contactsInfo = {
    title: 'БЛАГОДІЙНА ОРГАНІЗАЦІЯ «МІЖНАРОДНИЙ БЛАГОДІЙНИЙ ФОНД „ПЕРЕМОЖНА ВІДБУДОВА УКРАЇНИ“»',
    directorName: 'Дячук Владислав Омелянович',
    phone: '+380970446060',
    email: 'vladiss@i.ua',
}

const Contacts = () => {

    const { t } = useTranslation();

    return (
        <section className="section contacts" id="contacts">
            <div className="container">
                <div className="contacts-banner">
                    <img src="./images/about-banner-1.jpg" width="315" height="380" loading="lazy" alt="Tiger"
                        className="about-img w-100" />
                </div>
                <div className="contacts-content">
                <h2 className="h2  section-title"><strong>{t('contacts.our-contacts')}:</strong></h2>

                    <ul>
                        <li><h3>{t('contacts.icf-full-title')}: </h3>{contactsInfo.title}</li>
                        <li><h3>{t('contacts.director')}: </h3>{contactsInfo.directorName}</li>
                        <li><a target="_top" href={`tel:${contactsInfo.phone}`}><h3>{t('contacts.phone')}: </h3>{contactsInfo.phone}</a></li>
                        <li><a target="_top" href={`mailto:${contactsInfo.email}`}><h3>{t('contacts.email')}: </h3>{contactsInfo.email}</a></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Contacts;