import { useEffect, useState } from "react";
import DonationButton from "../donation/DonationButton";

import './Hero.css';
import { useTranslation } from "react-i18next";

const getMainCharityDonationInfo = async () => {
    return {
        title: 'Реквізити фонду "Переможна Відбудова України"',
        description: 'Якщо ви маєте бажання допомогти фонду у здійсненні добрих справ, але не знаєте на який саме збір направити ваші кошти, можете поповнити основний рахунок, і фонд буде розприділяти кошти за найважливішими потребами',
        link: 'https://google.com'
    }
}

const Hero = () => {

    const [mainCharityDonationInfo, setMainCharityDonationInfo] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        console.log('useEffect', mainCharityDonationInfo)
        const loadDonationInfo = async () => {
            const result = await getMainCharityDonationInfo();
            console.log(result)
            setMainCharityDonationInfo(result);
        };

        if (!mainCharityDonationInfo) {
            loadDonationInfo();
        }
    }, []);

    return (
        <section className="hero" id="home">
            <div className="container">
                <p className="section-subtitle">
                    <span>{t('hero.subheader')}</span>
                </p>
                <h2 className="h1 hero-title">
                    {t('hero.header-icf')}
                    <strong>"{t('hero.header-icf-name')}"</strong>
                </h2>
                <br />
                <br />
                <br />
                {mainCharityDonationInfo &&
                    (<DonationButton
                        linkForPayment={mainCharityDonationInfo.link}
                        modalDescription={mainCharityDonationInfo.description}
                        modalTitile={mainCharityDonationInfo.title}
                    />)
                }

            </div>
        </section>
    );
}

export default Hero;