
import { useEffect, useState } from 'react';
import SelectLanguage from '../select-language/SelectLanguage';
import DonationButton from '../donation/DonationButton';

import HeaderMenu from './HeaderMenu';
import './Header.css';

const getMainCharityDonationInfo = async () => {
  return {
    title: 'Реквізити фонду "Переможна Відбудова України"',
    description: 'Якщо ви маєте бажання допомогти фонду у здійсненні добрих справ, але не знаєте на який саме збір направити ваші кошти, можете поповнити основний рахунок, і фонд буде розприділяти кошти за найважливішими потребами',
    link: 'https://google.com'
  }
}

const Header = () => {
  const [mainCharityDonationInfo, setMainCharityDonationInfo] = useState(null);

  useEffect(() => {
    console.log('useEffect', mainCharityDonationInfo)
    const loadDonationInfo = async () => {
      const result = await getMainCharityDonationInfo();
      console.log(result)
      setMainCharityDonationInfo(result);
    };

    if (!mainCharityDonationInfo) {
      loadDonationInfo();
    }
  }, []);

  return (
    <header className="header">
      <div className="container">
        <h1>
          <a href="/" className="logo">
            <img className='logo-img' src='./images/ICF-VRU-logo.jpeg'></img>
          </a>
        </h1>
        <SelectLanguage />
        <HeaderMenu />
        {mainCharityDonationInfo &&
          (<div className='header-action'>
            <DonationButton
              linkForPayment={mainCharityDonationInfo.link}
              modalDescription={mainCharityDonationInfo.description}
              modalTitile={mainCharityDonationInfo.title}
            />
          </div>)
        }
      </div>
    </header>
  );
};

export default Header;