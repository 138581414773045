import { closeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import './Donation.css';

export const DonateModal = ({ title, description, actionLink, onClose }) => {
  
  return (
    <div onClick={onClose} className="modal-overlay">
      <div onClick={(event) => {
        event.stopPropagation();
      }} className="modal">
        <div className='close-action'>
          <button onClick={onClose} className="close-button">
            <IonIcon icon={closeOutline} />
          </button>
        </div>
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-body">
          <p className="body-text">{description}</p>
        </div>
        <div className="modal-footer">
          <a href={actionLink} className="action-link">Підтримати</a>
        </div>
      </div>
    </div>
  );
};

export default DonateModal;