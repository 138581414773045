import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="footer">
            <div className="container">
                <ul className="footer-list">
                    <li>
                        <a href="/" className="footer-link">{t('footer.home')}</a>
                    </li>
                    <li>
                        <a href="/contacts" className="footer-link">{t('footer.contacts')}</a>
                    </li>

                    <li>
                        <a href="/donate" className="footer-link">{t('footer.donate')}</a>
                    </li>
                </ul>
                <p className="copyright">                 
                </p>

            </div>
        </footer>
    );
}

export default Footer;