import DonateModal from './DonateModal';
import './Donation.css';
import { heartOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DonationButton = ({ linkForPayment, modalTitile, modalDescription }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="donation">

            <button onClick={openModal} className="btn btn-primary">
                <span>{t('donate.button-text')}</span>

                <IonIcon icon={heartOutline} aria-hidden="true"></IonIcon>
            </button>
            {isModalOpen && (
                <DonateModal
                    title={modalTitile}
                    description={modalDescription}
                    actionLink={linkForPayment}
                    onClose={closeModal}
                />
            )}
        </div>

    );
}

export default DonationButton;