
import { useTranslation } from 'react-i18next';
import About from '../about/About';
import Donation from '../donation/Donation';
import Hero from '../hero/Hero';
import Contacts from '../contacts/Contacts';

import './Main.css';


const Main = () => {

    const { t } = useTranslation();

    return (
        <main>
            <article>
                
                <Hero />

                <About />

                <Donation  title={t('donate.donation')} showPageRedirectButton={true} />

                <Contacts />

            </article>
        </main>

    );

}

export default Main;