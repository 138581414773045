import Reports from "./Reports";
import './Reports.css';

const data = [
    {
      images: ['./images/about-banner-1.jpg', './images/about-banner-2.jpg'],
      title: 'Інвалідний візок пораненому',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
    },
    {
      images: ['./images/about-banner-3.jpg', './images/about-banner-4.jpg'],
      title: 'Дрони для віськової частини',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.      Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempoEtiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.',
    },
    {
      images: ['./images/about-banner-3.jpg', './images/about-banner-1.jpg'],
      title: 'Card 3',
      description: 'This is the description for card 3.',
    },
    {
      images: ['./images/about-banner-4.jpg', './images/about-banner-2.jpg'],
      title: 'Card 4',
      description: 'This is the description for card 4.',
    },
  ];

const ReportsPage = () => {
    return (
        <div className='reports-page'>
            <Reports data={data}/>
        </div>
    )
}

export default ReportsPage;