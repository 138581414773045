import { heartOutline } from 'ionicons/icons';
import './About.css';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const About = ({ showPageRedirectButton = true }) => {

    const { t } = useTranslation();

    return (
        <section className="section about" id="about">
            <div className="container">

                <div className="about-banner">



                    <div className="banner-row">

                        <div className="banner-col">
                            <img src="./images/about-banner-1.jpg" width="315" height="380" loading="lazy" alt="Tiger"
                                className="about-img w-100" />

                            <img src="./images/about-banner-2.jpg" width="386" height="250" loading="lazy" alt="Panda"
                                className="about-img about-img-2 w-100" />
                        </div>

                        <div className="banner-col">
                            <img src="./images/about-banner-3.jpg" width="250" height="277" loading="lazy" alt="Elephant"
                                className="about-img about-img-3 w-100" />

                            <img src="./images/about-banner-4.jpg" width="315" height="380" loading="lazy" alt="Deer"
                                className="about-img w-100" />
                        </div>

                    </div>

                </div>

                <div className="about-content">

                    <p className="section-subtitle">
                        <span>{t('about.who-we-are')}</span>
                    </p>

                    <h2 className="h2 section-title">
                        {t('about.icf')} <strong> {t('about.icf-title')}</strong>
                    </h2>

                    <div className="tab-content">

                        <p className="section-text">
                            {t('about.icf-small-description')}
                        </p>

                        <br />
                        <br />
                        {showPageRedirectButton ?
                            <a href="/about" className="btn btn-secondary">
                                <span>{t('about.know-more-about-us')}</span>
                                <IonIcon icon={heartOutline} aria-hidden="true"></IonIcon>
                            </a> :
                            null}

                    </div>

                </div>
            </div>
        </section>
    );
}

export default About;