import Contacts from "./Contacts";
import './Contacts.css';

const ContactsPage = () => {
    return (
        <div className='contacts-page'>
            <Contacts/>
        </div>
    );
}

export default ContactsPage