import React, { useState } from 'react';
import './Reports.css';

const Reports = ({ data }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageList, setImageList] = useState([]);

    const openModal = (images, index) => {
        setImageList(images);
        setCurrentImageIndex(index);
        setSelectedImage(images[index]);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setImageList([]);
        setCurrentImageIndex(0);
    };

    const showNextImage = (e) => {
        e.stopPropagation();
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        setSelectedImage(imageList[(currentImageIndex + 1) % imageList.length]);
    };

    const showPreviousImage = (e) => {
        e.stopPropagation();
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length);
        setSelectedImage(imageList[(currentImageIndex - 1 + imageList.length) % imageList.length]);
    };

    return (
        <div>


            <ul className="report-list">
                {data.map((item, index) => (
                    <li key={index}>
                        <div className="report-card" key={index}>
                        <img
                            src={item.images[0]}
                            alt={item.title}
                            className="card-image"
                            onClick={() => openModal(item.images, 0)}
                        />
                        <h3 className="card-title">{item.title}</h3>
                        <p className="card-description">{item.description}</p>
                    </div>
                    </li>
                ))}
            </ul>
            {selectedImage && (
                <div className="modal-reports" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <img className="modal-content" src={selectedImage} alt="Selected" />
                    <button className="prev" onClick={showPreviousImage}>&#10094;</button>
                    <button className="next" onClick={showNextImage}>&#10095;</button>
                </div>
            )}
        </div>
    );
};

export default Reports;
