import About from './About';
import './About.css';

const AboutPage = () => {
    return (
        <div className='about-page'>
            <About showPageRedirectButton={false} />
            <div className="main-about-content">
                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.

                        Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempor.

                        Etiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.

                        Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempor.

                        Etiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique feugiat tincidunt. Vestibulum odio sapien, vulputate id efficitur in, vestibulum vitae justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat lobortis orci, eget rutrum risus elementum nec. Aenean dapibus nec augue in vehicula. Sed hendrerit erat libero, quis convallis nunc rutrum in. Curabitur porttitor, tellus ac placerat pellentesque, massa sapien eleifend massa, eget dictum ipsum orci ut mauris. Aenean ultrices rhoncus nulla, sit amet scelerisque quam vulputate et. Fusce ut nisl nec arcu pulvinar convallis in sit amet mi.

                        Nulla facilisi. Integer condimentum velit in luctus maximus. Fusce aliquam iaculis aliquam. Quisque nec felis a dui vulputate sodales sed sit amet risus. Curabitur ultricies tristique dignissim. Integer tincidunt tristique eleifend. Nullam consequat bibendum libero ac tempor.

                        Etiam cursus dolor vel blandit porttitor. Nunc laoreet tellus massa, non tempus ante consectetur at. Integer consectetur auctor purus, id ultricies dui volutpat quis. Etiam et tellus pretium, facilisis elit at, varius erat. Morbi nibh mauris, tempus et posuere et, posuere in ipsum. Maecenas sodales dictum nisi eget consequat. Vivamus tristique consequat rhoncus. Donec eget eros sit amet dui convallis vulputate. Nulla vel orci laoreet tortor tincidunt tempor.</p>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;