import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline, closeOutline, menuOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const HeaderMenu = () => {

    const [navbarOpened, changeNavbarOpened] = useState(false);
    const { t } = useTranslation();

    const munuButtons = [
        {
            title: t('header-menu.main'),
            url: '/',
        },
        {
            title: t('header-menu.about'),
            url: '/about',
        },
        {
            title: t('header-menu.donate'),
            url: '/donate',
        },
        {
            title: t('header-menu.reports'),
            url: '/reports',
        },
        {
            title: t('header-menu.contacts'),
            url: '/contacts',
        },
    ];

    return (
        <React.Fragment>
            <button onClick={() => changeNavbarOpened(true)} className="nav-open-btn" aria-label="Open Menu">
                <IonIcon icon={menuOutline}></IonIcon>
            </button>

            <nav className={`navbar ${navbarOpened ? 'active' : ''}`}>

                <button onClick={() => changeNavbarOpened(false)}className="nav-close-btn" aria-label="Close Menu">
                    <IonIcon icon={closeOutline}></IonIcon>
                </button>

                <a href="/" className="logo"><span>Благодійний фонд</span><span>Переможна відбудова України</span></a>

                <ul className="navbar-list">
                    {
                        munuButtons.map(btn => (<li key={btn.url}>
                            <a href={btn.url} className={`navbar-link`}>
                                <span>{btn.title}</span>

                                <IonIcon icon={chevronForwardOutline} aria-hidden="true"></IonIcon>
                            </a>
                        </li>))
                    }


                </ul>

            </nav>
        </React.Fragment>
    );
}

export default HeaderMenu;