import { useTranslation } from "react-i18next";
import Donation from "./Donation";
import './Donation.css';

const DonationPage = () => {
    const { t } = useTranslation();
    return (
        <div className='donation-page'>
            <Donation title={t('donate.help-for-civilians')} showPageRedirectButton={false}/>
            <Donation title={t('donate.help-for-armed-forces')} showPageRedirectButton={false}/>
        </div>
    );
}

export default DonationPage;